.mySwiper {
  z-index: 0 !important;
}
.mySwiper :global(.swiper-wrapper) {
  z-index: 0 !important;
  border-radius: 12px !important;
}

.mySwiper :global(.swiper-wrapper) img {
  border-radius: 12px !important;
}

@media screen and (max-width: 575px) {
  .mySwiper :global(.swiper-wrapper) {
    height: 128px;
  }

  .mySwiper :global(.swiper-wrapper) img {
    height: 128px;
    object-fit: fill;
    border-radius: 12px;
  }
}

.skeleton_banner {
  background: linear-gradient(90deg, hsl(210, 15%, 88%), hsl(210, 15%, 95%), hsl(210, 15%, 88%));
  background-size: 200%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
